<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- HEADER -->
                <div class="d-block pt-3 mb-4">
                    <div class="row align-items-center">
                        <div class="col">
                            <h1 class="header-title">
                                Custom Formulir
                                <small class="form-text text-muted mt-2">
                                    Menu ini Digunakan Untuk Melakukan Custom Formulir Pendaftaran
                                </small>
                            </h1>
                        </div>
                        <div class="col-auto">
                            <router-link :to="{name : 'master.formulir.setup', params : {id:this.$route.params.id}}" class="btn btn-outline-primary ml-2">
                                <div class="d-flex align-items-center">
                                    <i class="fe fe-repy mr-2"></i>Kembali
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <hr/>

            <div v-if="fetching">
                Sedang mengambil data...
            </div>
            <div v-if="!fetching" class="col-12 col-lg-12 col-xl-12">
                <div class="form-group mb-4 flex-grow-1">
                    <div class="d-block mb-5">
                        1. Formulir Asal Pendaftar
                        <div class="pull-right">
                            <button data-toggle="modal" data-target="#modalForm" type="button" v-on:click="addForm('asal_pendaftar','custom')" class="btn btn-sm btn-primary">Tambah Inputan Lain</button>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-sm table-nowrap card-table table-bordered">
                                <thead>
                                <tr>
                                    <td style="width:5%">No</td>
                                    <td>Nama Form</td>
                                    <td style="width:10%">Jenis Input</td>
                                    <td style="width:10%">Aktif</td>
                                    <td style="width:10%">Tidak</td>
                                    <td style="width:10%">Wajib Diisi</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Kewarganegaraan</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="kewarganegaraan" v-on:change="customForm('asal_pendaftar','default','kewarganegaraan','1')" :checked="asalPendaftar.default.kewarganegaraan.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="kewarganegaraan" v-on:change="customForm('asal_pendaftar','default','kewarganegaraan','0')" :checked="asalPendaftar.default.kewarganegaraan.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="kewarganegaraan_required" v-on:change="customFormRequired('asal_pendaftar','default','kewarganegaraan',asalPendaftar.default.kewarganegaraan.value)" v-model="requiredDefault['kewarganegaraan']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Asal Provinsi</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="asal_provinsi" v-on:change="customForm('asal_pendaftar','default','asal_provinsi','1')" :checked="asalPendaftar.default.asal_provinsi.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="asal_provinsi" v-on:change="customForm('asal_pendaftar','default','asal_provinsi','0')" :checked="asalPendaftar.default.asal_provinsi.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="asal_provinsi_required" v-on:change="customFormRequired('asal_pendaftar','default','asal_provinsi',asalPendaftar.default.asal_provinsi.value)" v-model="requiredDefault['asal_provinsi']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Kota Asal</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="asal_kota" v-on:change="customForm('asal_pendaftar','default','asal_kota','1')" :checked="asalPendaftar.default.asal_kota.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="asal_kota" v-on:change="customForm('asal_pendaftar','default','asal_kota','0')" :checked="asalPendaftar.default.asal_kota.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="asal_kota_required" v-on:change="customFormRequired('asal_pendaftar','default','asal_kota',asalPendaftar.default.asal_kota.value)" v-model="requiredDefault['asal_kota']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Asal Sekolah</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="asal_pilihan_sekolah" v-on:change="customForm('asal_pendaftar','default','asal_pilihan_sekolah','1')" :checked="asalPendaftar.default.asal_pilihan_sekolah.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="asal_pilihan_sekolah" v-on:change="customForm('asal_pendaftar','default','asal_pilihan_sekolah','0')" :checked="asalPendaftar.default.asal_pilihan_sekolah.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="asal_pilihan_sekolah_required" v-on:change="customFormRequired('asal_pendaftar','default','asal_pilihan_sekolah',asalPendaftar.default.asal_pilihan_sekolah.value)" v-model="requiredDefault['asal_pilihan_sekolah']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Nama Asal Sekolah</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="asal_sekolah" v-on:change="customForm('asal_pendaftar','default','asal_sekolah','1')" :checked="asalPendaftar.default.asal_sekolah.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="asal_sekolah" v-on:change="customForm('asal_pendaftar','default','asal_sekolah','0')" :checked="asalPendaftar.default.asal_sekolah.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="asal_sekolah_required" v-on:change="customFormRequired('asal_pendaftar','default','asal_sekolah',asalPendaftar.default.asal_sekolah.value)" v-model="requiredDefault['asal_sekolah']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>NPSN</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="npsn" v-on:change="customForm('asal_pendaftar','default','npsn','1')" :checked="asalPendaftar.default.npsn.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="npsn" v-on:change="customForm('asal_pendaftar','default','npsn','0')" :checked="asalPendaftar.default.npsn.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="npsn_required" v-on:change="customFormRequired('asal_pendaftar','default','npsn',asalPendaftar.default.npsn.value)" v-model="requiredDefault['npsn']">
                                    </td>
                                </tr>
                                <tr v-for="(item, index) in asalPendaftar.customList" :key="index">
                                    <td>{{(asalPendaftar.total_default+(index+1))}}</td>
                                    <td>{{item.name}}</td>
                                    <td class="text-center">{{ item.input == 'option' ? 'Pilihan' : 'Text' }}</td>
                                    <td colspan="3" >
                                        <button class="float-right btn btn-sm btn-danger ml-2" type="button" v-on:click="deleteFormById(item.id)">
                                            <i class="fa fa-trash"></i> Hapus
                                        </button>
                                        <button data-toggle="modal" data-target="#modalForm" class="float-right btn btn-sm btn-primary" type="button" v-on:click="editFormById(item.id,'asal_pendaftar')">
                                            <i class="fa fa-edit"></i> Ubah
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="form-group mb-4 flex-grow-1">
                    <div class="d-block mb-5">
                        2. Formulir Data Pendaftar
                        <div class="pull-right">
                            <button data-toggle="modal" data-target="#modalForm" type="button" v-on:click="addForm('data_pendaftar','custom')" class="btn btn-sm btn-primary">Tambah Inputan Lain</button>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-sm table-nowrap card-table table-bordered">
                                <thead>
                                <tr>
                                    <td style="width:5%">No</td>
                                    <td>Nama Form</td>
                                    <td style="width:10%">Jenis Input</td>
                                    <td style="width:10%">Aktif</td>
                                    <td style="width:10%">Tidak</td>
                                    <td style="width:10%">Wajib Diisi</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Nama Lengkap</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="nama_lengkap" v-on:change="customForm('data_pendaftar','default','nama_lengkap','1')" :checked="dataPendaftar.default.nama_lengkap.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="nama_lengkap" v-on:change="customForm('data_pendaftar','default','nama_lengkap','0')" :checked="dataPendaftar.default.nama_lengkap.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="nama_lengkap_required" v-on:change="customFormRequired('data_pendaftar','default','nama_lengkap',dataPendaftar.default.nama_lengkap.value)" v-model="requiredDefaultDataPendaftar['nama_lengkap']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Jenis Kelamin</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="jenis_kelamin" v-on:change="customForm('data_pendaftar','default','jenis_kelamin','1')" :checked="dataPendaftar.default.jenis_kelamin.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="jenis_kelamin" v-on:change="customForm('data_pendaftar','default','jenis_kelamin','0')" :checked="dataPendaftar.default.jenis_kelamin.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="jenis_kelamin_required" v-on:change="customFormRequired('data_pendaftar','default','jenis_kelamin',dataPendaftar.default.jenis_kelamin.value)" v-model="requiredDefaultDataPendaftar['jenis_kelamin']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Tempat Lahir</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="tempat_lahir" v-on:change="customForm('data_pendaftar','default','tempat_lahir','1')" :checked="dataPendaftar.default.tempat_lahir.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="tempat_lahir" v-on:change="customForm('data_pendaftar','default','tempat_lahir','0')" :checked="dataPendaftar.default.tempat_lahir.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="tempat_lahir_required" v-on:change="customFormRequired('data_pendaftar','default','tempat_lahir',dataPendaftar.default.tempat_lahir.value)" v-model="requiredDefaultDataPendaftar['tempat_lahir']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Tanggal Lahir</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="tanggal_lahir" v-on:change="customForm('data_pendaftar','default','tanggal_lahir','1')" :checked="dataPendaftar.default.tanggal_lahir.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="tanggal_lahir" v-on:change="customForm('data_pendaftar','default','tanggal_lahir','0')" :checked="dataPendaftar.default.tanggal_lahir.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="tanggal_lahir_required" v-on:change="customFormRequired('data_pendaftar','default','tanggal_lahir',dataPendaftar.default.tanggal_lahir.value)" v-model="requiredDefaultDataPendaftar['tanggal_lahir']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Alamat Lengkap</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="alamat_lengkap" v-on:change="customForm('data_pendaftar','default','alamat_lengkap','1')" :checked="dataPendaftar.default.alamat_lengkap.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="alamat_lengkap" v-on:change="customForm('data_pendaftar','default','alamat_lengkap','0')" :checked="dataPendaftar.default.alamat_lengkap.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="alamat_lengkap_required" v-on:change="customFormRequired('data_pendaftar','default','alamat_lengkap',dataPendaftar.default.alamat_lengkap.value)" v-model="requiredDefaultDataPendaftar['alamat_lengkap']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>NISN</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="nisn" v-on:change="customForm('data_pendaftar','default','nisn','1')" :checked="dataPendaftar.default.nisn.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="nisn" v-on:change="customForm('data_pendaftar','default','nisn','0')" :checked="dataPendaftar.default.nisn.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="nisn_required" v-on:change="customFormRequired('data_pendaftar','default','nisn',dataPendaftar.default.nisn.value)" v-model="requiredDefaultDataPendaftar['nisn']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Provinsi</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="provinsi" v-on:change="customForm('data_pendaftar','default','provinsi','1')" :checked="dataPendaftar.default.provinsi.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="provinsi" v-on:change="customForm('data_pendaftar','default','provinsi','0')" :checked="dataPendaftar.default.provinsi.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="provinsi_required" v-on:change="customFormRequired('data_pendaftar','default','provinsi',dataPendaftar.default.provinsi.value)" v-model="requiredDefaultDataPendaftar['provinsi']">
                                    </td>
                                </tr>

                                <tr>
                                    <td>8</td>
                                    <td>Kota</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="kota" v-on:change="customForm('data_pendaftar','default','kota','1')" :checked="dataPendaftar.default.kota.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="kota" v-on:change="customForm('data_pendaftar','default','kota','0')" :checked="dataPendaftar.default.kota.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="kota_required" v-on:change="customFormRequired('data_pendaftar','default','kota',dataPendaftar.default.kota.value)" v-model="requiredDefaultDataPendaftar['kota']">
                                    </td>
                                </tr>

                                <tr>
                                    <td>9</td>
                                    <td>Kecamatan</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="kecamatan" v-on:change="customForm('data_pendaftar','default','kecamatan','1')" :checked="dataPendaftar.default.kecamatan.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="kecamatan" v-on:change="customForm('data_pendaftar','default','kecamatan','0')" :checked="dataPendaftar.default.kecamatan.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="kecamatan_required" v-on:change="customFormRequired('data_pendaftar','default','kecamatan',dataPendaftar.default.kecamatan.value)" v-model="requiredDefaultDataPendaftar['kecamatan']">
                                    </td>
                                </tr>

                                <tr>
                                    <td>10</td>
                                    <td>Kelurahan</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="kelurahan" v-on:change="customForm('data_pendaftar','default','kelurahan','1')" :checked="dataPendaftar.default.kelurahan.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="kelurahan" v-on:change="customForm('data_pendaftar','default','kelurahan','0')" :checked="dataPendaftar.default.kelurahan.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="kelurahan_required" v-on:change="customFormRequired('data_pendaftar','default','kelurahan',dataPendaftar.default.kelurahan.value)" v-model="requiredDefaultDataPendaftar['kelurahan']">
                                    </td>
                                </tr>

                                <tr>
                                    <td>11</td>
                                    <td>RT</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="rt" v-on:change="customForm('data_pendaftar','default','rt','1')" :checked="dataPendaftar.default.rt.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="rt" v-on:change="customForm('data_pendaftar','default','rt','0')" :checked="dataPendaftar.default.rt.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="rt_required" v-on:change="customFormRequired('data_pendaftar','default','rt',dataPendaftar.default.rt.value)" v-model="requiredDefaultDataPendaftar['rt']">
                                    </td>
                                </tr>

                                <tr>
                                    <td>12</td>
                                    <td>RW</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="rw" v-on:change="customForm('data_pendaftar','default','rw','1')" :checked="dataPendaftar.default.rw.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="rw" v-on:change="customForm('data_pendaftar','default','rw','0')" :checked="dataPendaftar.default.rw.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="rw_required" v-on:change="customFormRequired('data_pendaftar','default','rw',dataPendaftar.default.rw.value)" v-model="requiredDefaultDataPendaftar['rw']">
                                    </td>
                                </tr>

                                <tr>
                                    <td>13</td>
                                    <td>Anak Ke</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="anak_ke" v-on:change="customForm('data_pendaftar','default','anak_ke','1')" :checked="dataPendaftar.default.anak_ke.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="anak_ke" v-on:change="customForm('data_pendaftar','default','anak_ke','0')" :checked="dataPendaftar.default.anak_ke.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="anak_ke_required" v-on:change="customFormRequired('data_pendaftar','default','anak_ke',dataPendaftar.default.anak_ke.value)" v-model="requiredDefaultDataPendaftar['anak_ke']">
                                    </td>
                                </tr>

                                <tr>
                                    <td>14</td>
                                    <td>Jumlah Saudara</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="jumlah_saudara" v-on:change="customForm('data_pendaftar','default','jumlah_saudara','1')" :checked="dataPendaftar.default.jumlah_saudara.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="jumlah_saudara" v-on:change="customForm('data_pendaftar','default','jumlah_saudara','0')" :checked="dataPendaftar.default.jumlah_saudara.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="jumlah_saudara_required" v-on:change="customFormRequired('data_pendaftar','default','jumlah_saudara',dataPendaftar.default.jumlah_saudara.value)" v-model="requiredDefaultDataPendaftar['jumlah_saudara']">
                                    </td>
                                </tr>

                                <tr>
                                    <td>15</td>
                                    <td>NIK</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="nik" v-on:change="customForm('data_pendaftar','default','nik','1')" :checked="dataPendaftar.default.nik.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="nik" v-on:change="customForm('data_pendaftar','default','nik','0')" :checked="dataPendaftar.default.nik.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="nik_required" v-on:change="customFormRequired('data_pendaftar','default','nik',dataPendaftar.default.nik.value)" v-model="requiredDefaultDataPendaftar['nik']">
                                    </td>
                                </tr>

                                <tr>
                                    <td>16</td>
                                    <td>Nomor Handphone</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="nomor_handphone" v-on:change="customForm('data_pendaftar','default','nomor_handphone','1')" :checked="dataPendaftar.default.nomor_handphone.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="nomor_handphone" v-on:change="customForm('data_pendaftar','default','nomor_handphone','0')" :checked="dataPendaftar.default.nomor_handphone.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="nomor_handphone_required" v-on:change="customFormRequired('data_pendaftar','default','nomor_handphone',dataPendaftar.default.nomor_handphone.value)" v-model="requiredDefaultDataPendaftar['nomor_handphone']">
                                    </td>
                                </tr>

                                <tr>
                                    <td>17</td>
                                    <td>Email</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="email" v-on:change="customForm('data_pendaftar','default','email','1')" :checked="dataPendaftar.default.email.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="email" v-on:change="customForm('data_pendaftar','default','email','0')" :checked="dataPendaftar.default.email.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="email_required" v-on:change="customFormRequired('data_pendaftar','default','email',dataPendaftar.default.email.value)" v-model="requiredDefaultDataPendaftar['email']">
                                    </td>
                                </tr>

                                <!--tr>
                                    <td>18</td>
                                    <td>Foto</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="foto" v-on:change="customForm('data_pendaftar','default','foto','1')" :checked="dataPendaftar.default.foto.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="foto" v-on:change="customForm('data_pendaftar','default','foto','0')" :checked="dataPendaftar.default.foto.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="foto_required" disabled checked><br/>
                                        <small>Foto Wajib Diisi</small>
                                    </td>
                                </tr-->
                                <tr v-for="(item, index) in dataPendaftar.customList" :key="index">
                                    <td>{{(dataPendaftar.total_default+(index+1))}}</td>
                                    <td>{{item.name}}</td>
                                    <td class="text-center">{{ item.input == 'option' ? 'Pilihan' : 'Text' }}</td>
                                    <td colspan="3">
                                        <button class="float-right btn btn-sm btn-danger ml-2" type="button" v-on:click="deleteFormById(item.id)">
                                            <i class="fa fa-trash"></i> Hapus
                                        </button>
                                        <button data-toggle="modal" data-target="#modalForm" class="float-right btn btn-sm btn-primary" type="button" v-on:click="editFormById(item.id,'data_pendaftar')">
                                            <i class="fa fa-edit"></i> Ubah
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="form-group mb-4 flex-grow-1">
                    <div class="d-block mb-5">
                        3. Formulir Data Wali
                        <br/>
                        <small>
                            Setup formulir berikut ini akan aktif/non-aktifkan di Data Ortu maupun Data Wali
                            Setiap ada kata wali ditambahkan wali / ortu
                        </small>

                        <div class="pull-right">
                            <button data-toggle="modal" data-target="#modalForm" type="button" v-on:click="addForm('data_wali','custom')" class="btn btn-sm btn-primary">Tambah Inputan Lain</button>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-sm table-nowrap card-table table-bordered">
                                <thead>
                                <tr>
                                    <td style="width:5%">No</td>
                                    <td>Nama Form</td>
                                    <td style="width:10%">Jenis Input</td>
                                    <td style="width:10%">Aktif</td>
                                    <td style="width:10%">Tidak</td>
                                    <td style="width:10%">Wajib Diisi</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Nama Wali</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="nama_wali" v-on:change="customForm('data_wali','default','nama_wali','1')" :checked="dataWali.default.nama_wali.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="nama_wali" v-on:change="customForm('data_wali','default','nama_wali','0')" :checked="dataWali.default.nama_wali.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="nama_wali_required" v-on:change="customFormRequired('data_wali','default','nama_wali',dataWali.default.nama_wali.value)" v-model="requiredDefaultWali['nama_wali']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Alamat Wali</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="alamat_wali" v-on:change="customForm('data_wali','default','alamat_wali','1')" :checked="dataWali.default.alamat_wali.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="alamat_wali" v-on:change="customForm('data_wali','default','alamat_wali','0')" :checked="dataWali.default.alamat_wali.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="alamat_wali_required" v-on:change="customFormRequired('data_wali','default','alamat_wali',dataWali.default.alamat_wali.value)" v-model="requiredDefaultWali['alamat_wali']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Telepon Wali</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="telepon_wali" v-on:change="customForm('data_wali','default','telepon_wali','1')" :checked="dataWali.default.telepon_wali.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="telepon_wali" v-on:change="customForm('data_wali','default','telepon_wali','0')" :checked="dataWali.default.telepon_wali.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="telepon_wali_required" v-on:change="customFormRequired('data_wali','default','telepon_wali',dataWali.default.telepon_wali.value)" v-model="requiredDefaultWali['telepon_wali']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Pekerjaan Wali</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="pekerjaan_wali" v-on:change="customForm('data_wali','default','pekerjaan_wali','1')" :checked="dataWali.default.pekerjaan_wali.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="pekerjaan_wali" v-on:change="customForm('data_wali','default','pekerjaan_wali','0')" :checked="dataWali.default.pekerjaan_wali.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="pekerjaan_wali_required" v-on:change="customFormRequired('data_wali','default','pekerjaan_wali',dataWali.default.pekerjaan_wali.value)" v-model="requiredDefaultWali['pekerjaan_wali']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Kantor Wali</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="kantor_wali" v-on:change="customForm('data_wali','default','kantor_wali','1')" :checked="dataWali.default.kantor_wali.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="kantor_wali" v-on:change="customForm('data_wali','default','kantor_wali','0')" :checked="dataWali.default.kantor_wali.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="kantor_wali_required" v-on:change="customFormRequired('data_wali','default','kantor_wali',dataWali.default.kantor_wali.value)" v-model="requiredDefaultWali['kantor_wali']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Email Wali</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="email_wali" v-on:change="customForm('data_wali','default','email_wali','1')" :checked="dataWali.default.email_wali.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="email_wali" v-on:change="customForm('data_wali','default','email_wali','0')" :checked="dataWali.default.email_wali.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="email_wali_required" v-on:change="customFormRequired('data_wali','default','email_wali',dataWali.default.email_wali.value)" v-model="requiredDefaultWali['email_wali']">
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Alamat Kantor Wali</td>
                                    <td class="text-center">-</td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="alamat_kantor_wali" v-on:change="customForm('data_wali','default','alamat_kantor_wali','1')" :checked="dataWali.default.alamat_kantor_wali.value == '1'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="radio" name="alamat_kantor_wali" v-on:change="customForm('data_wali','default','alamat_kantor_wali','0')" :checked="dataWali.default.alamat_kantor_wali.value == '0'">
                                    </td>
                                    <td style="width:10%;text-align:center !important">
                                        <input type="checkbox" name="alamat_kantor_wali_required" v-on:change="customFormRequired('data_wali','default','alamat_kantor_wali',dataWali.default.alamat_kantor_wali.value)" v-model="requiredDefaultWali['alamat_kantor_wali']">
                                    </td>
                                </tr>
                                <tr v-for="(item, index) in dataWali.customList" :key="index">
                                    <td>{{(dataWali.total_default+(index+1))}}</td>
                                    <td>{{item.name}}</td>
                                    <td class="text-center">{{ item.input == 'option' ? 'Pilihan' : 'Text' }}</td>
                                    <td colspan="3">
                                        <button class="float-right btn btn-sm btn-danger ml-2" type="button" v-on:click="deleteFormById(item.id)">
                                            <i class="fa fa-trash"></i> Hapus
                                        </button>
                                        <button data-toggle="modal" data-target="#modalForm" class="float-right btn btn-sm btn-primary" type="button" v-on:click="editFormById(item.id,'data_wali')">
                                            <i class="fa fa-edit"></i> Ubah
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <!-- Modal -->
            <div class="modal" id="modalForm" tabindex="-1" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style="overflow: hidden;">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center justify-content-between text-sm">
                                <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> {{this.label}} Custom Inputan</div>
                                <div>
                                    <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0"
                                        data-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                        

                        <form v-on:submit.prevent="onSubmit">
                            <div class="d-block p-3">
                                <div class="d-flex align-items-center pb-3 row">
                                    <div class="col-md-12">
                                        <div class="mb-3">
                                            <label>Kode : </label>
                                            <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan nama inputan..." v-model="form.code"
                                            :class="{ 'is-invalid': submitted && $v.form.code.$error }"
                                            >

                                            <div v-if="submitted && !$v.form.code.required" class="invalid-feedback d-block">
                                            Kode Form Wajib Diisi</div>
                                        </div>

                                        <div class="mb-3">
                                            <label>Nama : </label>
                                            <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan nama inputan..." v-model="form.name"
                                            :class="{ 'is-invalid': submitted && $v.form.name.$error }"
                                            >

                                            <div v-if="submitted && !$v.form.name.required" class="invalid-feedback d-block">
                                            Nama Form Wajib Diisi</div>
                                        </div>

                                        <div class="mb-3">
                                            <label>Jenis Inputan : </label>
                                            <select class="form-control" name="inputan" autocomplete="off"  id="inputan" v-model="form.inputan">
                                                <option value="text">Text</option>
                                                <option value="option">Pilihan</option>
                                            </select>
                                        </div>

                                        <div v-if="form.inputan == 'option'" class="card bg-light">
                                            <div class="row m-2" v-for="(list, index) in form.listOption" :key="index">
                                                <label class="col-12" >Value : </label>
                                                <input type="text" autocomplete="off" class="col-10 form-control" id="value" aria-describedby="value"  placeholder="Masukan value..." v-model="form.listOption[index]">
                                                &nbsp;
                                                <button type="button" class="col-1 btn btn-sm btn-secondary" v-on:click="removeValue(index)"><i class="fa fa-minus mr-1"></i></button>
                                                </div>
                                                <button type="button" class="col-3 mb-3 ml-2 btn btn-sm btn-primary mt-2" v-on:click="addValue()"><i class="fa fa-plus mr-1"></i>Tambah Value</button>
                                        </div>

                                        <div v-if="form.inputan == 'text'" class="mb-3">
                                            <label>Placeholder : </label>
                                            <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan nama inputan..." v-model="form.placeholder">
                                        </div>

                                        <div>
                                            <label>Wajib Diisi : </label>
                                            <select class="form-control" v-model="form.required">
                                                <option value="1">Iya</option>
                                                <option value="0">Tidak</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <button :disabled="waiting" type="submit" class="btn btn-primary btn-block">{{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                            </div>

                        </form>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Custom Formulir Pendaftaran'
        },
        data() {
            return {
                fetching        : true,
                dataFetch       : [],
                label           : 'Tambah',
                requiredDefault : [],
                requiredDefaultDataPendaftar : [],
                requiredDefaultWali : [],
                form            : {
                    id          : "",
                    code        : "",
                    name        : "",
                    placeholder : "",
                    flag        : "",
                    type        : "",
                    inputan     : "text",
                    value       : "",
                    required    : 0,
                    listOption  : [''],
                },
                asalPendaftar   : {
                    default: {
                        kewarganegaraan : {
                            required : '',
                            value : ''
                        },
                        asal_provinsi : {
                            required : '',
                            value : ''
                        },
                        asal_kota : {
                            required : '',
                            value : ''
                        },
                        asal_pilihan_sekolah : {
                            required : '',
                            value : ''
                        },
                        asal_sekolah : {
                            required : '',
                            value : ''
                        },
                        npsn : {
                            required : '',
                            value : ''
                        }
                    },
                    customList : []
                },
                dataPendaftar   : {
                    default: {
                        nama_lengkap : {
                            required : '',
                            value : ''
                        },
                        jenis_kelamin : {
                            required : '',
                            value : ''
                        },
                        tempat_lahir : {
                            required : '',
                            value : ''
                        },
                        tanggal_lahir : {
                            required : '',
                            value : ''
                        },
                        alamat_lengkap : {
                            required : '',
                            value : ''
                        },
                        nisn : {
                            required : '',
                            value : ''
                        },
                        provinsi : {
                            required : '',
                            value : ''
                        },
                        kota : {
                            required : '',
                            value : ''
                        },
                        kecamatan : {
                            required : '',
                            value : ''
                        },
                        kelurahan : {
                            required : '',
                            value : ''
                        },
                        rt : {
                            required : '',
                            value : ''
                        },
                        rw : {
                            required : '',
                            value : ''
                        },
                        anak_ke : {
                            required : '',
                            value : ''
                        },
                        jumlah_saudara : {
                            required : '',
                            value : ''
                        },
                        nik : {
                            required : '',
                            value : ''
                        },
                        nomor_handphone : {
                            required : '',
                            value : ''
                        },
                        email : {
                            required : '',
                            value : ''
                        },
                        foto : {
                            required : '',
                            value : ''
                        }
                    }
                },
                dataWali   : {
                    default: {
                        nama_wali : {
                            required : '',
                            value : ''
                        },
                        alamat_wali : {
                            required : '',
                            value : ''
                        },
                        telepon_wali : {
                            required : '',
                            value : ''
                        },
                        pekerjaan_wali : {
                            required : '',
                            value : ''
                        },
                        kantor_wali : {
                            required : '',
                            value : ''
                        },
                        email_wali : {
                            required : '',
                            value : ''
                        },
                        alamat_kantor_wali : {
                            required : '',
                            value : ''
                        },
                    },
                    customList : []
                },
                submitted       : false,
                waiting         : false
            }
        },
        created() {
            this.getAsalPendaftar()
            this.getDataPendaftar()
            this.getDataWali()
        },
        validations: {
            form: {
                code: {
                    required
                },
                name: {
                    required
                },
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(this.$apiconfig + 'admin/setupformulir/saveCustomForm', {
                        formulir_id : this.$route.params.id,
                        flag        : this.form.flag,
                        type        : this.form.type,
                        code        : this.form.code,
                        name        : this.form.name,
                        id          : this.form.id,
                        placeholder : this.form.placeholder,
                        required    : this.form.required,
                        inputan     : this.form.inputan,
                        nilai       : this.form.value,
                        option      : this.form.listOption,
                        value       : 1
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    document.getElementById("btnCloseModal").click();
                                    this.getAsalPendaftar();
                                    this.getDataPendaftar();
                                    this.getDataWali();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });

                            this.waiting    = false;
                            this.submitted  = false;
                        }
                    })
                }
            },
            getAsalPendaftar: function() {
                this.fetching = true;
                this.$http.get(this.$apiconfig + 'admin/setupformulir/listComponentForm', {
                    params: {
                        formulir_id : this.$route.params.id,
                        flag : 'asal_pendaftar'
                    }
                })
                .then((response) => {
                    if(response.data.status) {
                        this.asalPendaftar  = response.data.data
                        
                        this.requiredDefault['kewarganegaraan']         = this.asalPendaftar.default.kewarganegaraan.required;
                        this.requiredDefault['asal_provinsi']           = this.asalPendaftar.default.asal_provinsi.required;
                        this.requiredDefault['asal_kota']               = this.asalPendaftar.default.asal_kota.required;
                        this.requiredDefault['asal_pilihan_sekolah']    = this.asalPendaftar.default.asal_pilihan_sekolah.required;
                        this.requiredDefault['asal_sekolah']            = this.asalPendaftar.default.asal_sekolah.required;
                        this.requiredDefault['npsn']                    = this.asalPendaftar.default.npsn.required;
                    }
                    this.fetching       = false;
                })
            },
            getDataPendaftar: function() {
                this.fetching = true;
                this.$http.get(this.$apiconfig + 'admin/setupformulir/listComponentForm', {
                    params: {
                        formulir_id : this.$route.params.id,
                        flag : 'data_pendaftar'
                    }
                })
                .then((response) => {
                    if(response.data.status) {
                        this.dataPendaftar  = response.data.data
                        
                        this.requiredDefaultDataPendaftar['nama_lengkap']   = this.dataPendaftar.default.nama_lengkap.required;
                        this.requiredDefaultDataPendaftar['jenis_kelamin']  = this.dataPendaftar.default.jenis_kelamin.required;
                        this.requiredDefaultDataPendaftar['tempat_lahir']   = this.dataPendaftar.default.tempat_lahir.required;
                        this.requiredDefaultDataPendaftar['tanggal_lahir']  = this.dataPendaftar.default.tanggal_lahir.required;
                        this.requiredDefaultDataPendaftar['alamat_lengkap'] = this.dataPendaftar.default.alamat_lengkap.required;
                        this.requiredDefaultDataPendaftar['nisn']           = this.dataPendaftar.default.nisn.required;
                        this.requiredDefaultDataPendaftar['provinsi']       = this.dataPendaftar.default.provinsi.required;
                        this.requiredDefaultDataPendaftar['kota']           = this.dataPendaftar.default.kota.required;
                        this.requiredDefaultDataPendaftar['kecamatan']      = this.dataPendaftar.default.kecamatan.required;
                        this.requiredDefaultDataPendaftar['kelurahan']      = this.dataPendaftar.default.kelurahan.required;
                        this.requiredDefaultDataPendaftar['rt']             = this.dataPendaftar.default.rt.required;
                        this.requiredDefaultDataPendaftar['rw']                 = this.dataPendaftar.default.rw.required;
                        this.requiredDefaultDataPendaftar['anak_ke']            = this.dataPendaftar.default.anak_ke.required;
                        this.requiredDefaultDataPendaftar['jumlah_saudara']     = this.dataPendaftar.default.jumlah_saudara.required;
                        this.requiredDefaultDataPendaftar['nik']                = this.dataPendaftar.default.nik.required;
                        this.requiredDefaultDataPendaftar['nomor_handphone']    = this.dataPendaftar.default.nomor_handphone.required;
                        this.requiredDefaultDataPendaftar['email']              = this.dataPendaftar.default.email.required;
                        this.requiredDefaultDataPendaftar['foto']               = this.dataPendaftar.default.foto.required;
                    }
                    this.fetching       = false;
                })
            },
            getDataWali: function() {
                this.fetching = true;
                this.$http.get(this.$apiconfig + 'admin/setupformulir/listComponentForm', {
                    params: {
                        formulir_id : this.$route.params.id,
                        flag : 'data_wali'
                    }
                })
                .then((response) => {
                    if(response.data.status) {
                        this.dataWali  = response.data.data

                        this.requiredDefaultWali['nama_wali']         = this.dataWali.default.nama_wali.required;
                        this.requiredDefaultWali['alamat_wali']           = this.dataWali.default.alamat_wali.required;
                        this.requiredDefaultWali['telepon_wali']               = this.dataWali.default.telepon_wali.required;
                        this.requiredDefaultWali['pekerjaan_wali']    = this.dataWali.default.pekerjaan_wali.required;
                        this.requiredDefaultWali['kantor_wali']            = this.dataWali.default.kantor_wali.required;
                        this.requiredDefaultWali['email_wali']                    = this.dataWali.default.email_wali.required;
                        this.requiredDefaultWali['alamat_kantor_wali']                    = this.dataWali.default.alamat_kantor_wali.required;
                    }
                    this.fetching       = false;
                })
            },
            customForm: function(flag, type, name, value) {
                this.$http.post(this.$apiconfig + 'admin/setupformulir/saveCustomForm', {
                        formulir_id : this.$route.params.id,
                        flag        : flag,
                        type        : type,
                        code        : name,
                        name        : name,
                        value       : value
                    })
                .then(response => {
                    if (response.data.status) {
                        console.log("Data berhasil di simpan");
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                    console.debug("Login issue: " + error)
                });
            },
            addForm: function(flag, type, ) {
                this.form.id            = ""
                this.form.code          = ""
                this.form.name          = ""
                this.form.required      = 0
                this.form.placeholder   = ""
                this.form.flag = flag
                this.form.type = type
                this.form.listOption = [''];
            },
            deleteFormById: function (id) {
                this.$swal({
                    icon: 'info',
                    title: 'Hapus Data',
                    text: 'Apakah kamu yakin ingin menghapus data ini?',
                    confirmButtonText: 'Hapus',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(this.$apiconfig + 'admin/setupformulir/deleteCustomForm', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getAsalPendaftar();
                                    this.getDataPendaftar();
                                    this.getDataWali();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            editFormById: function(id, flag) {
                let item = [];
                if(flag == 'asal_pendaftar') {
                    item = this.asalPendaftar.customList.find(item => item.id === id);
                }
                
                if(flag == 'data_pendaftar') {
                    item = this.dataPendaftar.customList.find(item => item.id === id);
                }
                
                if(flag == 'data_wali') {
                    item = this.dataWali.customList.find(item => item.id === id);
                }

                this.form.id   = item.id;
                this.form.code = item.code;
                this.form.name = item.name;
                this.form.flag = item.flag;
                this.form.type = item.type;
                this.form.required = item.required;
                this.form.inputan  = item.input;
                this.form.placeholder = item.placeholder;
                if(item.input == 'text'){
                    this.form.listOption  = [''];
                }else{
                    this.form.listOption  = item.option;
                }
                this.label = 'Ubah';
            },
            customFormRequired: function(flag, type, name, value) {
                let required    = 0;
                if(flag == 'asal_pendaftar') {
                    required    = this.requiredDefault[name];
                }
                else if(flag == 'data_pendaftar') {
                    required    = this.requiredDefaultDataPendaftar[name];
                } else {
                    required    = this.requiredDefaultWali[name];
                }

                let required_data = 0;
                if(required == true) {
                    required_data = 1;
                }

                this.$http.post(this.$apiconfig + 'admin/setupformulir/saveCustomForm', {
                        formulir_id : this.$route.params.id,
                        flag        : flag,
                        type        : type,
                        code        : name,
                        name        : name,
                        value       : value,
                        required    : required_data
                    })
                .then(response => {
                    if (response.data.status) {
                        console.log("Data berhasil di simpan");
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                    console.debug("Login issue: " + error)
                });
            },
            addValue: function() {
                this.form.listOption.push('');
            },
            removeValue: function(index) {
                this.form.listOption.splice(index,1);
            }
        }
    }
</script>